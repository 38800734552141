<router-outlet>
  <app-spinner></app-spinner>
</router-outlet>

<button
  *ngIf="showHome"
  routerLink="/unafraid/main"
  type="button"
  class="fixed-button btn btn-circle btn-lg btn-secondary rounded-circle"
>
  <i class="fa fa-home"></i>
</button>
<app-toasts aria-live="polite" aria-atomic="true"></app-toasts>