import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { BehaviorSubject, Observable } from "rxjs";
import { environment } from "../../environments/environment";
import { AppleLoginProvider } from "../authentication/apple.provider";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  private apiUrl = environment.apiUrl + "/auth";
  private url = environment.apiUrl;
  unfuserSubject = new BehaviorSubject<any>(null);

  constructor(private http: HttpClient) {}

  login(email: string, password: string): Observable<any> {
    const url = `${this.apiUrl}/login`;
    const body = { email, password };
    return this.http.post(url, body);
  }

  register(data: any): Observable<any> {
    return this.http.post(`${this.apiUrl}/register`, data);
  }

  resetPasswd(email) {
    return this.http.post(`${this.apiUrl}/forget-password`, email);
  }

  resetPass(payload) {
    return this.http.post(`${this.apiUrl}/reset-password`, payload);
  }

  verification(chk) {
    return this.http.get(`${environment.apiUrl}/verification/${chk}`);
  }

  updateLastLogin() {
    return this.http.patch(`${environment.apiUrl}/last-login`, {});
  }

  updateOptoutSettings(payload: Record<string, string | number | Record<string, boolean>>): 
  Observable<any> {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${payload["token"]}`,
    });
    return this.http.patch(
      `${environment.apiUrl}/update-optout-settings`,
      payload["body"],
      {
        headers,
      },
    );
  }

  logout() {
    return this.http.get(`${environment.apiUrl}/auth/logout`);
  }

  editProfile(payload) {
    return this.http.post(`${this.url}/user`, payload);
  }

  getUser(token) {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${token}`,
    });

    return this.http.get(`${environment.apiUrl}/user`, { headers });
  }

  uploadAvatar(payload) {
    return this.http.post(`${this.url}/upload-images`, payload);
  }
}
