import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { AuthType, LoadLogin, LoadLoginFailure } from './auth.actions';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';

@Injectable()
export class AuthEffects {
  emailTemp = null;
  login$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthType.login),
      switchMap((obj: any) => {
        if (obj.payload.email === undefined) {
          return of(new LoadLoginFailure(null));
        }
        return this.authService
          .login(obj.payload.email, obj.payload.password)
          .pipe(
            map((response: any) => {
              if (response?.status) {
                localStorage.setItem('unftoken', response.token);
                localStorage.setItem('unfuser', JSON.stringify(response.data));
              }
              return new LoadLogin(response);
            }),
            tap((z) => {
              const onboarding = z.payload.data.onboarding;
              if (onboarding) {
                this.router.navigate(['/unafraid/main']);
              } else {
                this.router.navigate(['/unafraid/onboarding']);
              }
            }),
            catchError((e) => {
              const errorMessage = e.error || 'Unknown error';
              return of(new LoadLoginFailure({ errors: errorMessage }));
            })
          );
      })
    )
  );

  constructor(
    private actions$: Actions,
    private authService: AuthService,
    private router: Router
  ) {}
}
