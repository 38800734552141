import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, catchError, throwError } from 'rxjs';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { selectAuth } from './state/auth.selectors';
import { AuthState } from './state/auth.reducer';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private store: Store<AuthState>, private router: Router) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let token: any = localStorage.getItem('unftoken');

    const isPublic = request.url.includes('/authentication');

    if (token && !isPublic) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`,
        },
      });
    }

    return next.handle(request).pipe(
      catchError((error: any) => {
        console.error({ error });
        if (error instanceof HttpErrorResponse && error.status === 401) {
          localStorage.clear();
          //    this.router.navigate(['/unafraid/main']);
        }

        return throwError(error);
      })
    );
  }
}
