import { Routes } from '@angular/router';

import { BlankComponent } from './layouts/blank/blank.component';
import { FullComponent } from './layouts/full/full.component';

export const Approutes: Routes = [
  {
    path: '',
    redirectTo: 'unafraid/main',
    pathMatch: 'full',
  },
  {
    path: '',
    component: BlankComponent,
    data: { isPublic: true },
    children: [
      {
        path: 'authentication',
        loadChildren: () =>
          import('./authentication/authentication.module').then(
            (m) => m.AuthenticationModule
          ),
      },
    ],
  },
  {
    path: '',
    component: FullComponent,
    children: [
      {
        path: 'unafraid',
        loadChildren: () =>
          import('./unafraid/unafraid.module').then((m) => m.UnafraidModule),
      }
    ],
  },
  {
    path: '**',
    redirectTo: '/authentication/login',
  },
];
