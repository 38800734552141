import { CommonModule } from '@angular/common';
import {
  Component,
  OnInit,
  HostListener,
  EventEmitter,
  Output,
  ElementRef,
  Renderer2,
  AfterViewInit,
  ViewChild,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import {
  ActivatedRoute,
  NavigationEnd,
  NavigationExtras,
  Router,
  RouterModule,
} from '@angular/router';
import {
  NgbCollapseModule,
  NgbDropdown,
  NgbDropdownModule,
  NgbNavModule,
} from '@ng-bootstrap/ng-bootstrap';
import { FeatherModule } from 'angular-feather';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { BreadcrumbComponent } from '../../shared/breadcrumb/breadcrumb.component';
import { VerticalNavigationComponent } from '../../shared/vertical-header/vertical-navigation.component';
import { VerticalSidebarComponent } from '../../shared/vertical-sidebar/vertical-sidebar.component';
import { HorizontalNavigationComponent } from '../../shared/horizontal-header/horizontal-navigation.component';
import { HorizontalSidebarComponent } from '../../shared/horizontal-sidebar/horizontal-sidebar.component';
import { AuthService } from './../../services/auth.service';
import { LogoutLogin } from './../../state/auth.actions';
import { Store } from '@ngrx/store';
import { selectAuth } from '../../state/auth.selectors';
import { asyncScheduler, catchError, filter, of } from 'rxjs';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-full-layout',
  standalone: true,
  imports: [
    RouterModule,
    CommonModule,
    NgbDropdownModule,
    NgScrollbarModule,
    FeatherModule,
    NgbNavModule,
    FormsModule,
    BreadcrumbComponent,
    VerticalNavigationComponent,
    VerticalSidebarComponent,
    HorizontalNavigationComponent,
    HorizontalSidebarComponent,
    NgbCollapseModule,
  ],
  templateUrl: './full.component.html',
  styleUrls: ['./full.component.scss'],
})
export class FullComponent implements OnInit, AfterViewInit {
  @ViewChild('vlayout') vlayout: ElementRef | undefined;
  @ViewChild('hamb') hamb: ElementRef | undefined;
  active = 1;
  storageUrl = environment.storage;
  @Output() toggleSidebar = new EventEmitter<void>();
  constructor(
    public router: Router,
    private authService: AuthService,
    private store: Store,
    private route: ActivatedRoute,
    private renderer: Renderer2
  ) {}
  showSearch = false;
  tabStatus = 'justified';

  public isCollapsed = false;

  public innerWidth: any;
  public defaultSidebar: any;
  public showSettings = false;
  public showMobileMenu = false;
  public expandLogo = true;

  options = {
    theme: 'light', // two possible values: light, dark
    dir: 'ltr', // two possible values: ltr, rtl
    layout: 'vertical', // fixed value. shouldn't be changed.
    sidebartype: 'full', // four possible values: full, iconbar, overlay, mini-sidebar
    sidebarpos: 'fixed', // two possible values: fixed, absolute
    headerpos: 'fixed', // two possible values: fixed, absolute
    boxed: 'full', // two possible values: full, boxed
    navbarbg: 'skin1', // six possible values: skin(1/2/3/4/5/6)
    sidebarbg: 'skin6', // six possible values: skin(1/2/3/4/5/6)
    logobg: 'skin6', // six possible values: skin(1/2/3/4/5/6)
  };

  user = null;

  currentRoute = null;

  edit(dropdown: NgbDropdown) {
    dropdown.close();
  }

  Logo() {
    this.expandLogo = !this.expandLogo;
  }

  ngAfterViewInit() {
    this.renderer.listen('document', 'click', (event: Event) => {
      this.onDocumentClick(event);
    });
  }

  onDocumentClick(event: Event) {
    let none1 = true;
    let none2 = true;
    if (
      !(this.vlayout && this.vlayout?.nativeElement?.contains(event.target))
    ) {
      none1 = false;
    }

    if (!(this.hamb && this.hamb?.nativeElement?.contains(event.target))) {
      none2 = false;
    }
    if (
      (none1 === false && none2 === false) ||
      (!this.showMobileMenu && none2 === true)
    ) {
      this.showMobileMenu = false;
    } else {
      this.showMobileMenu = true;
    }
  }

  ngOnInit() {
    this.defaultSidebar = this.options.sidebartype;
    this.handleSidebar();
    this.authService.unfuserSubject.subscribe((data) => {
      this.loadUser();
    });

    this.authService.unfuserSubject.next(true);

    this.currentRoute = this.router.url;

    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.currentRoute = event.url;

        if (this.currentRoute === '/unafraid/invite-others') {
          this.showMobileMenu = false;
        }
      });
  }

  loadUser() {
    this.user = JSON.parse(localStorage.getItem('unfuser'));
  }

  handleSidebar() {
    this.innerWidth = window.innerWidth;
    switch (this.defaultSidebar) {
      case 'full':
      case 'iconbar':
        if (this.innerWidth < 1170) {
          this.options.sidebartype = 'mini-sidebar';
        } else {
          this.options.sidebartype = this.defaultSidebar;
        }
        break;

      case 'overlay':
        if (this.innerWidth < 767) {
          this.options.sidebartype = 'mini-sidebar';
        } else {
          this.options.sidebartype = this.defaultSidebar;
        }
        break;

      default:
    }
  }

  toggleSidebarType() {
    switch (this.options.sidebartype) {
      case 'full':
      case 'iconbar':
        this.options.sidebartype = 'mini-sidebar';
        break;

      case 'overlay':
        this.showMobileMenu = !this.showMobileMenu;
        break;

      case 'mini-sidebar':
        if (this.defaultSidebar === 'mini-sidebar') {
          this.options.sidebartype = 'full';
        } else {
          this.options.sidebartype = this.defaultSidebar;
        }
        break;

      default:
    }
  }

  handleClick(event: boolean) {
    this.showMobileMenu = event;
  }

  logout() {
    this.authService
      .logout()
      .pipe(
        catchError((e) => {
          localStorage.removeItem('unftoken');
          localStorage.removeItem('unfuser');
          this.router.navigate(['/']);
          asyncScheduler.schedule(() => {
            window.location.reload();
          }, 10);
          return of(true);
        })
      )
      .subscribe((data: any) => {
        if (data?.status) {
          this.store.dispatch(new LogoutLogin(null));
          localStorage.removeItem('unftoken');
          localStorage.removeItem('unfuser');

          this.router.navigate(['/']);
          asyncScheduler.schedule(() => {
            window.location.reload();
          }, 10);
        }
      });
  }

  ham() {
    this.showMobileMenu = !this.showMobileMenu;
  }
}
