import { AuthType } from './auth.actions';

export interface AuthState {
  user: any | null;
  error: string | null;
  signup: any | null;
  afraid: any | null;
  journal: any | null;
}

export const initialState: AuthState = {
  user: null,
  error: null,
  signup: null,
  afraid: null,
  journal: null,
};

export function authReducer(state: AuthState = initialState, action: any) {
  switch (action.type) {
    case AuthType.login:
      return {
        ...state,
        user: action.payload,
      };
    case AuthType.loginFailure:
      return {
        ...state,
        error: action.payload,
      };
    case AuthType.setSignUp:
      return {
        ...state,
        signup: action.payload,
      };
    case AuthType.clearErrors:
      return {
        ...state,
        error: null,
      };
    case AuthType.logout:
      return {
        ...state,
        user: action.payload,
        error: action.payload,
      };
    case AuthType.setAfraid:
      return {
        ...state,
        afraid: action.payload,
      };
    case AuthType.loginByProvider:
      return {
        ...state,
        user: action.payload,
      };
    case AuthType.setJournal:
      return {
        ...state,
        journal: action.payload,
      };
    default:
      return state;
  }
}
