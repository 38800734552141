<nav class="sidebar-nav">
    <ul id="sidebarnav">
        
        <!-- First level menu -->
        <li class="sidebar-item p-3" [class.active]="showMenu === sidebarnavItem.title"
            *ngFor="let sidebarnavItem of sidebarnavItems"
            (click)="sidebarnavItem.submenu.length != 0 ? '' : handleNotify()"
            >
            <div class="nav-small-cap" *ngIf="sidebarnavItem.extralink === true"><i
                    [ngClass]="[sidebarnavItem.icon]"></i><span class="hide-menu">{{sidebarnavItem.title |
                    translate}}</span></div>
            <a class="sidebar-link waves-effect waves-dark"
                [routerLink]="sidebarnavItem.class === '' ? [sidebarnavItem.path] : null"
                [ngClass]="[sidebarnavItem.class]" *ngIf="!sidebarnavItem.extralink;"
                [class.active]="sidebarnavItem?.relatedPaths?.includes(this.path)"
                (click)="addExpandClass(sidebarnavItem.title)"
                [routerLinkActive]="'active'">
                <i-feather [name]="sidebarnavItem.icon" [ngClass]="[sidebarnavItem.icon]"></i-feather>
                <span class="hide-menu">{{sidebarnavItem.title |translate}}
                    <span *ngIf="sidebarnavItem.label != '' "
                        [ngClass]="[sidebarnavItem.labelClass]">{{sidebarnavItem.label | translate}}</span>
                </span>
            </a> 

               <!-- Second level menu -->
               <ul aria-expanded="true" class="first-level" [class.collapse]="!this.path.includes(sidebarnavItem.path)" *ngIf="sidebarnavItem.submenu.length > 0"
                [ngClass]="{'in' : showMenu === sidebarnavItem.title }">
                <li class="sidebar-item" *ngFor="let sidebarnavSubItem of sidebarnavItem.submenu"
                    [class.active]="showSubMenu === sidebarnavSubItem.title"
                    [routerLinkActive]="sidebarnavSubItem.submenu.length > 0 ? '' : 'active'">
                    <a class="sidebar-link"
                        [routerLink]="sidebarnavSubItem.submenu.length > 0 ? null : [sidebarnavSubItem.path]"
                        [routerLinkActive]="sidebarnavSubItem.submenu.length != 0 ? '' : 'router-link-active'"
                        [ngClass]="[sidebarnavSubItem.class]" *ngIf="!sidebarnavSubItem.extralink;"
                        (mouseover)="addActiveClass(sidebarnavSubItem.title)">
                        <i [ngClass]="[sidebarnavSubItem.icon]"></i>
                        <span class="hide-menu">{{sidebarnavSubItem.title | translate}}</span>
                    </a>
                </li>
            </ul>
           
        </li>
    </ul>
</nav>