import { Action } from '@ngrx/store';

export enum AuthType {
  login = '[Auth] Login',
  logout = '[Auth] Logout',
  loginSuccess = '[Auth] Login Success',
  clearErrors = '[Auth] Clear Error',
  loginFailure = '[Auth] Login Failure',
  setSignUp = '[Auth] save data for first screen of register',
  setAfraid = '[Configuration] save afraids',
  loginByProvider = '[Auth] login by provider',
  setJournal = '[Journal] set a journal from history'
}

export class LoadLogin implements Action {
  readonly type = AuthType.login;
  constructor(public payload: any) {}
}

export class LogoutLogin implements Action {
  readonly type = AuthType.logout;
  constructor(public payload: any) {}
}

export class LoadLoginSuccess implements Action {
  readonly type = AuthType.loginSuccess;
  constructor(public payload: any) {}
}

export class LoadLoginFailure implements Action {
  readonly type = AuthType.loginFailure;
  constructor(public payload: any) {}
}

export class SetSignUp implements Action {
  readonly type = AuthType.setSignUp;
  constructor(public payload: any) {}
}

export class ClearErrors implements Action {
  readonly type = AuthType.clearErrors;
  constructor(public payload: any) {}
}

export class SetAfraids implements Action {
  readonly type = AuthType.setAfraid;
  constructor(public payload: any) {}
}

export class LoadLoginByProvider implements Action {
  readonly type = AuthType.loginByProvider;
  constructor(public payload: any) {}
}

export class SetJournal implements Action {
  readonly type = AuthType.setJournal;
  constructor(public payload: any) {}
}

export type Actions =
  | LoadLogin
  | LoadLoginSuccess
  | LoadLoginFailure
  | SetSignUp
  | LogoutLogin
  | SetAfraids
  | LoadLoginByProvider
  | SetJournal;
