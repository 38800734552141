import { ActionReducerMap } from '@ngrx/store';

import * as Auth from './auth.reducer';

/**
 * Main model
 */
export const reducers: ActionReducerMap<any> = {
  auth: Auth.authReducer
};
