import { RouteInfo } from './vertical-sidebar.metadata';

export const ROUTES: RouteInfo[] = [
  {
    path: '/unafraid/main',
    title: 'Home',
    icon: 'archive',
    class: '',
    extralink: false,
    label: '',
    labelClass: '',
    submenu: [],
  },
  {
    path: '/unafraid/about-us',
    title: 'About US',
    icon: 'command',
    class: '',
    extralink: false,
    label: '',
    labelClass: '',
    submenu: [],
  },
  {
    path: '/unafraid/my-dashboard',
    title: 'My Dashboard',
    icon: 'pie-chart',
    class: '',
    extralink: false,
    label: '',
    labelClass: '',
    submenu: [],
  },
  {
    path: '/unafraid/videos',
    title: 'Videos/Library',
    icon: 'film',
    class: '',
    extralink: false,
    label: '',
    labelClass: '',
    submenu: [],
  },
  {
    path: '/unafraid/journal',
    title: 'Journal Entries',
    icon: 'sunrise',
    class: '',
    extralink: false,
    label: '',
    labelClass: '',
    submenu: [],
  },
  {
    path: '/unafraid/reflections',
    title: 'Reflections',
    icon: 'circle',
    class: '',
    extralink: false,
    label: '',
    labelClass: '',
    submenu: [],
  },
  {
    path: '/unafraid/butterfly-effect/posts',
    title: 'Butterfly Effect',
    icon: 'twitter',
    class: '',
    extralink: false,
    label: '',
    labelClass: '',
    submenu: [],
    // submenu: [
    //   {
    //     path: '/unafraid/butterfly-effect/posts',
    //     title: 'Posts',
    //     icon: 'image',
    //     class: '',
    //     extralink: false,
    //     label: '',
    //     labelClass: '',
    //     submenu: [],
    //   },
    //   {
    //     path: '/unafraid/butterfly-effect/my-posts',
    //     title: 'My Posts',
    //     icon: 'image',
    //     class: '',
    //     extralink: false,
    //     label: '',
    //     labelClass: '',
    //     submenu: [],
    //   },
    //   {
    //     path: '/unafraid/butterfly-effect/create',
    //     title: 'Create',
    //     icon: 'mouse-pointer',
    //     class: '',
    //     extralink: false,
    //     label: '',
    //     labelClass: '',
    //     submenu: [],
    //   },
    // ],
  },
  {
    path: '/unafraid/goals',
    title: 'Goals/Hooks',
    icon: 'mouse-pointer',
    class: '',
    extralink: false,
    label: '',
    labelClass: '',
    submenu: [],
  },
  {
    path: '/unafraid/invite-others',
    title: 'Invite Others',
    icon: 'link',
    class: '',
    extralink: false,
    label: '',
    labelClass: '',
    submenu: [],
  },
];
