import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from './services/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  user = null;
  token: string | null = null;
  showHome = false;

  paths = [
    '/unafraid/journal',
    '/unafraid/butterfly-effect',
    '/unafraid/create-butterfly-effect-post',
    '/unafraid/journal-form',
    '/unafraid/reflections',
    '/unafraid/about-us',
    '/unafraid/invite-others',
    '/unafraid/help',
    '/unafraid/my-dashboard',
    '/unafraid/videos',
    '/unafraid/goals',
    '/unafraid/content-recommended',
  ];

  constructor(private router: Router, private authService: AuthService) {}

  updateLastLogin() {
    let userToken = false;

    try {
      this.user = JSON.parse(localStorage.getItem('unfuser'));
      this.token = localStorage.getItem('unftoken');
      userToken = !!this.user?.token || !!this.token;
    } catch (_) {}

    if (userToken) {
      this.authService
        .updateLastLogin()
        .pipe()
        .subscribe((_: any) => {});
    }

    return;
  }

  ngOnInit() {
    this.updateLastLogin();
    this.router.events.subscribe((event) => {
      this.showHome = false;
      if (this.paths.includes(this.router.url)) {
        this.showHome = true;
      }
    });
  }
}
