<!-- ============================================================== -->
<!-- Main wrapper - style you can find in pages.scss -->
<!-- ============================================================== -->
<div
  id="main-wrapper"
  [ngClass]="{ 'show-sidebar': showMobileMenu }"
  [dir]="options.dir"
  [attr.data-theme]="options.theme"
  [attr.data-layout]="options.layout"
  [attr.data-sidebartype]="options.sidebartype"
  [attr.data-sidebar-position]="options.sidebarpos"
  [attr.data-header-position]="options.headerpos"
  [attr.data-boxed-layout]="options.boxed"
  data-sidebartype="full"
>
  <!-- ============================================================== -->
  <!-- Topbar header - style you can find in topbar.scss -->
  <!-- ============================================================== -->
  <header *ngIf="currentRoute !== '/unafraid/onboarding' && currentRoute !== '/unafraid/invite-others'" class="topbar" [attr.data-navbarbg]="options.navbarbg">
    <nav
      class="navbar navbar-light d-flex h100 align-items-center"
      [ngClass]="{
        'gradient-main':
          currentRoute === '/unafraid/main' ||
          currentRoute === '/unafraid/profile' ||
          currentRoute === '/unafraid/help' ||
          currentRoute === '/unafraid/about-us',
        'butterfly-effect-dark': currentRoute.includes('butterfly-effect'),
        'gradient-journal':
          currentRoute === '/unafraid/journal' ||
          currentRoute === '/unafraid/journal-form',
        'gradient-goals': currentRoute === '/unafraid/goals',
        'gradient-videos': currentRoute === '/unafraid/videos',
        'gradient-dashboard':
          currentRoute === '/unafraid/my-dashboard' ||
          currentRoute.includes('/content-recommended'),
        'gradient-reflection': currentRoute === '/unafraid/reflections'
      }"
    >
      <div class="container">
        <!-- Menú Hamburguesa y Logo -->
        <div class="navbar-nav float-start box-header" #hamb>
          <a
            (click)="ham()"
            class="nav-toggler waves-effect waves-light d-block"
          >
            <i
              class="hamburger"
              [ngClass]="showMobileMenu ? 'ti-close' : 'ti-menu'"
            ></i>
          </a>
        </div>

        <!-- Elementos en el Centro -->
        <div class="d-lg-flex mx-auto">
          <span class="text-white fw700" *ngIf="user"
            >Hi, {{ user?.name }}👋</span
          >
        </div>

        <!-- Logos y Botón de Lupa en el Lado Derecho -->
        <div class="navbar-nav float-end box-header">
          <img
            routerLink="/unafraid/help"
            src="assets/images/logos/red-cross.svg"
            alt="red cross"
            width="20"
            class="me-2 btn-header cross"
          />

          <div
            class="link-unitive text-white fw700"
            *ngIf="!user"
            routerLink="authentication/login"
          >
            Login
          </div>

          <div
            *ngIf="user"
            ngbDropdown
            class="d-inline-block"
            #myDropdown="ngbDropdown"
          >
            <button
              type="button"
              class="btn btn-circle rounded-circle"
              id="dropdownBasic1"
              ngbDropdownToggle
            >
              <img
                *ngIf="user"
                [src]="this.storageUrl + user.avatar"
                alt="user"
                class="me-2 btn-header user-img"
              />
            </button>
            <div
              class="submenu"
              ngbDropdownMenu
              aria-labelledby="dropdownBasic1"
            >
              <button
                (click)="edit(myDropdown)"
                routerLink="/unafraid/profile"
                title="Edit"
                ngbDropdownItem
                class="btn-profile b1"
              >
                <i-feather name="edit-2"></i-feather>

                Edit Profile
              </button>
              <button (click)="logout()" ngbDropdownItem class="btn-profile b2">
                <i-feather name="log-out"></i-feather>
                Logout
              </button>
            </div>
          </div>

          <!-- <ul class="navbar-nav float-start me-auto">

            <li class="nav-item search-box">
              <a
                (click)="showSearch = !showSearch"
                class="nav-link waves-effect waves-dark"
                href="javascript:void(0)"
              >
                <i-feather  name="search"></i-feather>
              </a>
              <form
                class="app-search position-absolute"
                [ngClass]="{ 'show-search': showSearch }"
              >
                <input
                  type="text"
                  class="form-control"
                  placeholder="Search &amp; enter"
                />
                <a class="srh-btn" (click)="showSearch = !showSearch">
                  <i class="ti-close"></i>
                </a>
              </form>
            </li>
          </ul>-->
        </div>
      </div>
    </nav>
  </header>
  <!-- ============================================================== -->
  <!-- Left Sidebar - style you can find in sidebar.scss  -->
  <!-- ============================================================== -->
  <aside 
  *ngIf="currentRoute !== '/unafraid/onboarding' && currentRoute !== '/unafraid/invite-others'"
    class="left-sidebar"
    [ngClass]="{'left-custom':showMobileMenu, 'left-custom-1':!showMobileMenu}"
    [attr.data-sidebarbg]="options.sidebarbg"
    (mouseover)="Logo()"
    (mouseout)="Logo()"
  >
    <!-- Sidebar scroll-->
    <div *ngIf="options.layout === 'horizontal'; else vlayout">
      <app-horizontal-sidebar></app-horizontal-sidebar>
    </div>

    <!-- Horizontal Header -->
    <ng-template #vlayout class="sidebar">
      <app-vertical-sidebar 
          *ngIf="showMobileMenu"
          (notify)="handleClick($event)"
        >
        </app-vertical-sidebar>
    </ng-template>
    <!-- End Sidebar scroll-->
  </aside>
  <!-- ============================================================== -->
  <!-- End Left Sidebar - style you can find in sidebar.scss  -->
  <!-- ============================================================== -->
  <!-- ============================================================== -->
  <!-- Page wrapper  -->
  <!-- ============================================================== -->
  <div #side
    [ngClass]="{
      'bg-main':
        currentRoute === '/unafraid/main' ||
        currentRoute === '/unafraid/privacy' ||
        currentRoute === '/unafraid/terms' ||
        currentRoute === '/unafraid/onboarding' ||
        currentRoute === '/unafraid/profile' ||
        currentRoute === '/unafraid/help' ||
        currentRoute === '/unafraid/about-us',
      'bg-journal':
        currentRoute === '/unafraid/journal' ||
        currentRoute === '/unafraid/journal-form',
      'bg-goals': currentRoute === '/unafraid/goals',
      'bg-invite': currentRoute === '/unafraid/invite-others',
      'bg-videos': currentRoute === '/unafraid/videos',
      'bg-dashboard':
        currentRoute === '/unafraid/my-dashboard' ||
        currentRoute.includes('/content-recommended'),
      'bg-reflection': currentRoute === '/unafraid/reflections',
      'bg-butterfly-effect': currentRoute.includes('butterfly-effect'),
      'page-wrapper': currentRoute !== '/unafraid/onboarding' && currentRoute !== '/unafraid/invite-others',
      'page-wrapper-custom':showMobileMenu, 'page-wrapper-custom-1':!showMobileMenu
    }"
  >
    <!-- ============================================================== -->
    <!-- Container fluid  -->
    <!-- ============================================================== -->
    <div class="container-fluid">
      <!-- ============================================================== -->
      <!-- Start Page Content -->
      <!-- ============================================================== -->
      <router-outlet></router-outlet>
      <!-- ============================================================== -->
      <!-- End Start Page Content -->
      <!-- ============================================================== -->
    </div>
    <!-- ============================================================== -->
    <!-- End Container fluid  -->
    <!-- ============================================================== -->
    <!-- ============================================================== -->
    <!-- footer -->
    <!-- ============================================================== -->
    <footer *ngIf="currentRoute !== '/unafraid/onboarding' && currentRoute !== '/unafraid/invite-others'" class="footer text-center">
      <img
        *ngIf="currentRoute !== '/unafraid/main'"
        class="logo-unitive"
        src="assets/images/logos/logo-footer.svg"
        alt="logo unitive"
      />
      <div class="text-footer">
        <div class="links-container">
        <a href="/unafraid/privacy" >Privacy</a>
|
        <a href="/unafraid/terms" >Terms​</a>
    </div>
        Unafraid is a subsidiary of Unitive Global, LLC
      </div>
    </footer>
      <div *ngIf="currentRoute === '/unafraid/onboarding'" class="b-footer"></div>
    <!-- ============================================================== -->
    <!-- End footer -->
    <!-- ============================================================== -->
  </div>
  <!-- ============================================================== -->
  <!-- End Page wrapper  -->
  <!-- ============================================================== -->
</div>
