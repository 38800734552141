<!-- ============================================================== -->
<!-- Only router without any element -->
<!-- ============================================================== -->
<div class="container-unitive" style="background: linear-gradient(180deg, #977FB2 0%, #B090D3 100%);
" >
<div style="background:url(assets/images/boxes.svg)">
    <router-outlet></router-outlet>
</div>
    
</div>
